import { HelpOutline } from '@mui/icons-material'
import * as React from 'react'
import { formatDate, isWindow } from '@/@core/utils/utils'
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
  Drawer,
  Grid,
  IconButton,
  Pagination,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf'
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`

const PdfRenderer = ({ fileUrl }: { fileUrl: string }) => {
  const canvasRef = React.useRef<any>(null)
  const [pdf, setPdf] = React.useState<any>(null)

  const [numPages, setNumPages] = React.useState<any>(null)

  const [currentPage, setCurrentPage] = React.useState(1)

  const [load, setLoad] = React.useState(true)

  console.log(456)

  React.useEffect(() => {
    const loadPdf = async () => {
      const loadingTask = pdfjsLib.getDocument(fileUrl)

      const loadedPdf = await loadingTask.promise

      setPdf(loadedPdf)

      setNumPages(loadedPdf.numPages)

      // 初次加载时渲染第一页
      console.log(pdf)

      if (loadedPdf) {
        console.log(123)

        setLoad(false)

        renderPage(loadedPdf, 1)
      }
    }

    loadPdf()
  }, [fileUrl]) // 注意这里的依赖数组，确保只在 fileUrl 变化时重新加载 PDF

  const renderPage = async (pdf: PDFDocumentProxy, pageNumber: number) => {
    const page = await pdf.getPage(pageNumber)

    const viewport = page.getViewport({ scale: 1 })

    const canvas = canvasRef.current

    if (canvas) {
      const context = canvas.getContext('2d')

      canvas.height = viewport.height

      canvas.width = viewport.width

      const renderContext = {
        canvasContext: context,

        viewport: viewport
      }

      await page.render(renderContext).promise
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const page = parseInt(`${value}`, 10)

    if (page > 0 && page <= numPages && pdf) {
      setCurrentPage(page)

      renderPage(pdf, page)
    }
  }

  const handlePageChange = (event: { target: { value: string } }) => {
    const page = parseInt(event.target.value, 10)

    if (page > 0 && page <= numPages && pdf) {
      setCurrentPage(page)

      renderPage(pdf, page)
    }
  }

  return (
    <Stack alignItems={'center'} justifyContent={'center'}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
        id="backdrop"
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack spacing={2}>
        <Pagination
          count={numPages}
          page={currentPage}
          onChange={handleChange}
        />
      </Stack>

      {/* <input
        type="number"
        value={currentPage}
        onChange={handlePageChange}
        min="1"
        max={numPages}
        style={{ width: '50px', marginBottom: '10px' }}
      /> */}

      <canvas ref={canvasRef} />
    </Stack>
  )
}

const HelpPdf = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const pdfUrl = '/GLsofort软件图文演示20250303.pdf'

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box>
      <Tooltip title={t('Instructions for Use')} placement="top-start">
        <IconButton
          onClick={() => {
            // setOpen(true)

            window.open(pdfUrl, '_blank')
          }}
        >
          <HelpOutline />
        </IconButton>
      </Tooltip>

      <Drawer
        anchor={'right'}
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        hideBackdrop={true}
        sx={{
          '& .MuiPaper-root': {
            height: '90%',
            bottom: 0,
            top: '8%'
          },
          width: 0,
          right: 500
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          GLSofort {t('Instructions for Use')}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              width: '100%'
            }}
          >
            <PdfRenderer fileUrl={pdfUrl} />
          </Box>
        </DialogContent>
      </Drawer>
    </Box>
  )
})

export default HelpPdf
