// ** MUI Imports
import Box from '@mui/material/Box'
import { Theme, styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import InputAdornment from '@mui/material/InputAdornment'

import React from 'react'

// ** Next Import
import Link from 'next/link'

// ** Icons Imports
import Menu from 'mdi-material-ui/Menu'
import Magnify from 'mdi-material-ui/Magnify'

// ** Navigation Imports
import VerticalNavItems from 'src/navigation/vertical'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Components
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
import Navigation from '../horizontal/navigation'
import ChatClaude from './ChatClaude'
import { HelpOutline } from '@mui/icons-material'
import HelpPdf from '@/@core/layouts/components/shared-components/Help'
import FilterSelect from '@/@core/layouts/components/shared-components/FilterSelect'
import { ExternalLinks } from '@/@core/services/api'
import { isWindow } from '@/@core/utils/utils'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

const LinkStyled = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none'
})

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props
  const [value, setValue] = React.useState('')

  let type = 'window'

  if (/(micromessenger)/i.test(navigator.userAgent)) {
    // alert('微信')
    type = 'mobile'
  } else {
    // alert('普通浏览器')

    // 判断h5还是pc true就是h5
    const client =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    if (client) {
      // alert('h5')
      type = 'mobile'
    } else {
      // alert('pc')
      type = 'window'
    }
  }

  // ** Hook
  const hiddenSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <LinkStyled href="/" passHref>
        <img width={150} src="/images/logos/genomelight_logo.png" alt="" />
      </LinkStyled>

      <Box
        className="actions-left"
        sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
      >
        {hidden && type === 'window' ? (
          <>
            <Box
              className="actions-left"
              sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
            >
              <Navigation
                settings={props.settings}
                horizontalNavItems={VerticalNavItems()}
              />
            </Box>
          </>
        ) : (
          <>
            <IconButton
              color="inherit"
              onClick={toggleNavVisibility}
              sx={{ ml: -2.75, ...(hiddenSm ? {} : { mr: 3.5 }) }}
            >
              <Menu />
            </IconButton>
          </>
        )}
      </Box>

      <Box
        className="actions-right"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <TextField
          size="small"
          sx={{ '& .MuiOutlinedInput-root': { borderRadius: 4 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Magnify fontSize="small" />
              </InputAdornment>
            )
          }}
          placeholder={'hg38,4-515999-C-T,PIGG'}
          value={value}
          onChange={(e) => {
            console.log(e.target.value)

            setValue(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.which === 13) {
              // 处理回车键事件
              if (value.includes(',') || value.includes('，')) {
                const genome_build =
                  value.split(',').join('，').split('，')[0] ?? 'hg38'

                const variant =
                  value.split(',').join('，').split('，')[1] ?? '4-515999-C-T'

                const geneName =
                  value.split(',').join('，').split('，')[2] ?? 'PIGG'

                if (genome_build && variant) {
                  window.open(
                    `/vgfree/?genome_build=${genome_build}&variant=${variant}&geneName=${geneName}`
                  )

                  setValue('')
                }

                e.preventDefault()

                return false
              }
            }
          }}
        />

        <HelpPdf />

        {isWindow() ? (
          <LanguageDropdown settings={settings} saveSettings={saveSettings} />
        ) : (
          ''
        )}

        <ChatClaude />

        <IconButton
          onClick={() => {
            // window.open(ExternalLinks.vgfree)
            window.open('/vgfree')
          }}
        >
          <Box
            component="img"
            src="/images/logos/VGlogoHighRes.png" // 注意路径，如果放在public文件夹下可以直接使用/public/your-icon.png
            alt="Custom Icon"
            sx={{
              width: '46px', // 根据需要调整尺寸
              height: '30px',
              cursor: 'pointer'

              // 可以添加其他样式，如color: 'inherit'以匹配主题颜色（尽管这对PNG无效）
            }}
          />
        </IconButton>

        {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}

        {/* <NotificationDropdown /> */}

        {/* <FilterSelect /> */}

        <UserDropdown />
      </Box>
    </Box>
  )
}

export default AppBarContent
